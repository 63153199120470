$bg: #fff;
$text: #382b22;
$light-pink: #fff0f0;
$pink: #ffe9e9;
$dark-pink: #f9c4d2;
$pink-border: #b18597;
$pink-shadow: #ffe3e2;
.EmptyImage {
    // height: 100vh;
    display: flex;
    justify-content: center;
    padding-top: 200px;
}
.ImageOpacity {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
}
.ImageTitle {
    font-weight: 400;
    font-size: 24px;
    line-height: 50px;
    color: #132243;
}
.ConnectArea {
    display: flex;
    justify-content: center;
    .ButtonConnect {
        padding: 9px 33px;
        background: #fd1f6d;
        border-radius: 4px;
        color: white;
        display: flex;
        align-items: center;
        font-size: 20px;
        cursor: pointer;
        > div {
            display: flex;
            align-items: center;
            &:nth-child(2) {
                margin-left: 4px;
            }
        }
    }
}
.modalSelectPlatformItem {
    width: 150px;
    border: 2px solid #58bfda;
    border-radius: 8px;
    padding: 36px 0 12px;
    background-color: #e4f6fa;
    text-align: center;
    filter: grayscale(100%);
    &:not(:first-child) {
        margin-left: 40px;
    }
    &:hover {
        filter: none;
    }
}
.modalSelectPlatformItemImage {
    display: block;
    width: 60px;
    height: 60px;
    margin: 0 auto;
}
.modalSelectPlatformItemName {
    display: block;
    margin-top: 20px;
    font-size: 14px;
    color: #000;
}

// Trang style
.logo {
    font-weight: bold;
    font-size: 25px;
}

.website {
    font-size: 18px;
    border: solid 1px #ccc;
    padding: 5px;
    border-radius: 10px;
    background-color: #e3f2fd;
    &:hover {
        cursor: pointer;
        background-color: #e0ffff;
    }
    // &:active {
    //     cursor: pointer;
    //     background-color: #e0ffff;
    // }
}

.inputLinkContainer {
    width: 100%;
}

.inputLink {
    width: 100%;
    font-size: 15px;
    border: solid 0.1px #ccc;
    border-radius: 10px;
    padding: 10px;
}

.downloadBtn {
    display: inline-block;
    font-weight: 400;
    color: #7e7e7e;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.75rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: #fff;
    background-color: #2bc155;
    border-color: #2bc155;
    margin-top: 20px;
    &:hover {
        cursor: pointer;
    }
}

// style for change pass form
.mainDiv {
    display: flex;
    min-height: 100%;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    font-family: 'Open Sans', sans-serif;
}
.cardStyle {
    width: 500px;
    border-color: white;
    background: #fff;
    padding: 36px 0;
    border-radius: 4px;
    margin: 30px 0;
    box-shadow: 0px 0 2px 0 rgba(0, 0, 0, 0.25);
}
#signupLogo {
    max-height: 100px;
    margin: auto;
    display: flex;
    flex-direction: column;
}
.formTitle {
    font-weight: 600;
    margin-top: 20px;
    color: #2f2d3b;
    text-align: center;
}
.inputLabel {
    font-size: 12px;
    color: #555;
    margin-bottom: 6px;
    margin-top: 24px;
}
.inputDiv {
    width: 70%;
    display: flex;
    flex-direction: column;
    margin: auto;
}
input {
    height: 40px;
    font-size: 16px;
    border-radius: 4px;
    border: none;
    border: solid 1px #ccc;
    padding: 0 11px;
}
input:disabled {
    cursor: not-allowed;
    border: solid 1px #eee;
}
.buttonWrapper {
    margin-top: 40px;
}
.submitButton {
    width: 70%;
    height: 40px;
    margin: auto;
    display: block;
    color: #fff;
    background-color: #065492;
    border-color: #065492;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.035);
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}
.submitButton:disabled,
button[disabled] {
    border: 1px solid #cccccc;
    background-color: #cccccc;
    color: #666666;
}

#loader {
    position: absolute;
    z-index: 1;
    margin: -2px 0 0 10px;
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #666666;
    width: 14px;
    height: 14px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

// style for information page
.infoWrapper {
    display: flex;
}
.infoField {
    width: 30%;
    font-weight: bold;
}
.infoVal {
    width: 40%;
}

.saveBtn {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    font-size: inherit;
    font-family: inherit;
    &.saveBtn {
        font-weight: 600;
        color: $text;
        text-transform: uppercase;
        padding: 0.75em 2em;
        background: $light-pink;
        border: 2px solid $pink-border;
        border-radius: 0.75em;
        transform-style: preserve-3d;
        transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), background 150ms cubic-bezier(0, 0, 0.58, 1);
        &::before {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: $dark-pink;
            border-radius: inherit;
            box-shadow: 0 0 0 2px $pink-border, 0 0.625em 0 0 $pink-shadow;
            transform: translate3d(0, 0.75em, -1em);
            transition: transform 150ms cubic-bezier(0, 0, 0.58, 1), box-shadow 150ms cubic-bezier(0, 0, 0.58, 1);
        }
        &:hover {
            background: $pink;
            transform: translate(0, 0.25em);
            &::before {
                box-shadow: 0 0 0 2px $pink-border, 0 0.5em 0 0 $pink-shadow;
                transform: translate3d(0, 0.5em, -1em);
            }
        }
        &:active {
            background: $pink;
            transform: translate(0em, 0.75em);
            &::before {
                box-shadow: 0 0 0 2px $pink-border, 0 0 $pink-shadow;
                transform: translate3d(0, 0, -1em);
            }
        }
    }
}

// profile
.markType {
    font-weight: bold;
    font-size: 20px;
    border: 1px solid rgba(11, 42, 151, 0.1);
    border-radius: 50%;
    padding: 4px 8px;
    position: relative;
    margin-left: 15px;
    color: green;
    background-color: rgba(11, 42, 151, 0.1);
}

.mark {
    position: absolute;
    font-size: 13px;
    top: -50%;
    right: -40%;
    border: 0.5px solid #f94687;
    background-color: #f94687;
    color: #fff;
    padding: 2px 7px;
    border-radius: 50%;
}

.active {
    color: #ffffff;
    background-color: red;
}

// header
.headerWrapper {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    z-index: 1200;
    width: 100%;
}

// history page
.paramWrapper {
    display: flex;
    width: 100%;
}

.inputWrapper {
    width: 30%;
}
.paramLabel {
    margin-right: 10px;
    width: 30%;
}
.paramValue {
    margin-right: 30px;
    width: 70%;
}

@media only screen and (max-width: 800px) {
    .paramWrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .inputWrapper {
        width: 60%;
        margin-top: 20px;
    }
    .paramLabel {
        margin-right: 10px;
        width: 30%;
    }
    .paramValue {
        padding-right: 30px;
        width: 70%;
    }
}

// history table
table {
    border: 1px solid #ccc;
    width: 100%;
    border-collapse: collapse;
}
tr,
th,
td {
    border: 1px solid #ccc;
}

tbody td {
    padding: 5px;
}
// tbody tr {
//     &:hover {
//         background-color: #9adcfd;
//     }
// }

th {
    height: 30px;
}
.centerText {
    text-align: center;
}
.tableFooter {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}
.limit {
    padding-left: 5px;
    margin: auto 10px;
}
.pageChange button {
    background-color: #4caf50; /* Green */
    border: none;
    color: white;
    padding: 5px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    margin-left: 10px;
    border-radius: 6px;
    &:hover {
        cursor: pointer;
    }
    &:disabled {
        background-color: #555;
        color: #fff;
        cursor: default;
    }
}

.addPoint {
    background-color: #4caf50;
    color: #fff;
    line-height: 100%;
    border: 1px solid #4caf50;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.addPage {
    background-color: #009988;
    color: #fff;
    line-height: 100%;
    border: 1px solid #009988;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.block {
    background-color: #fa2b3f;
    color: #fff;
    line-height: 100%;
    border: 1px solid #fa2b3f;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.unBlock {
    background-color: #4356b9;
    color: #fff;
    line-height: 100%;
    border: 1px solid #4356b9;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}

.popupBox {
    position: fixed;
    background: #00000050;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 20000;
}

.box {
    position: relative;
    width: 40%;
    margin: 0 auto;
    height: auto;
    max-height: 70vh;
    margin-top: calc(100vh - 85vh - 20px);
    background: #fff;
    border-radius: 4px;
    padding: 20px;
    border: 1px solid #999;
    overflow: auto;
}

.closeIcon {
    content: 'x';
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    background: #e70c0c;
    width: 25px;
    height: 25px;
    /* border-radius: 50%; */
    line-height: 20px;
    text-align: center;
    border: 1px solid #999;
    font-size: 20px;
    color: #fff;
}

.controlIcon {
    width: 15px;
    height: 15px;
}

.extend {
    background-color: #2b92fa;
    color: #fff;
    line-height: 100%;
    border: 1px solid #2b92fa;
    &:hover {
        cursor: pointer;
        opacity: 0.8;
    }
}
